{

    class aflVaultItemListController {
        constructor() {
        }

        selectVaultItem(item) {
            this.selectedItem = item;
            this.onSelect({item});
        }
    }

    angular.module('adminApp')
        .component('aflVaultItemList', {
            controller: aflVaultItemListController,
            templateUrl: '/static/javascript/admin/afl-vault/afl-vault-item-list/afl-vault-item-list.html',
            bindings: {
                vaultItems: '=',
                selectedItem: '=',
                onSelect: '&'
            }
        });
}
